body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: #3290a8;
}

h1 {
    font-size: 3em;
    color: #fff;
    text-align: center;
}

h2 {
    font-size: 1.5em;
    color: #fff;
    text-align: center;
}

h3 {
    font-size: 1.2em;
    color: #fff;
    text-align: center;
}

button {
    background: #fff;
    border: 0;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1em;
    color: #3290a8;
    cursor: pointer;
    margin: auto;
    width: 200px;
}

input {
    background: #fff;
    border: 0;
    padding: 10px;
    border-radius: 5px;
    font-size: 2em;
    color: #3290a8;
    margin: 20px;
    width: 500px;
}

p {
    font-size: 1em;
    color: #fff;
    text-align: center;
}

.game {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    padding: 10px;
    border: solid 10px #003877;
}
